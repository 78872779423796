import { Box, Container, Grid, Text } from "@mantine/core";
import Link from "next/link";
import Image from "next/image";

const WeddingFooter = () => {
  return (
    <footer className="footer-v1">
      <Container size="xl" className="container--1">
        <Grid justify="center">
          <Grid.Col span={{ base: 12, md: 10 }}>
            <Grid>
              {/* Logo and Description Section */}
              <Grid.Col span={{ base: 12, sm: 4, lg: 4 }} className="pe-5">
                <Link href="/" passHref>
                  <Box component="span">
                    <Image
                      src="images/foot-logo.svg"
                      alt="Footer Logo"
                      width={150}
                      height={50}
                    />
                  </Box>
                </Link>
                <Text className="slogan" mt="sm">
                  Your go-to platform for planning Indian weddings, festivals, and events. Discover trusted vendors, expert tips, and everything you need for a seamless, vibrant celebration.
                </Text>
                
              </Grid.Col>

              {/* Product Links Section */}
              <Grid.Col span={{ base: 12, sm: 2, lg: 2 }}>
                <Text component="h4" className="lev-1" mb="sm">
                  Product
                </Text>
                <Box>
                  <Link href="/festivals" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Festivals
                    </Text>
                  </Link>
                  <Link href="/wedding" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Weddings
                    </Text>
                  </Link>
                  <Link href="/events" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Events
                    </Text>
                  </Link>
                  <Link href="/blog" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Our Blogs
                    </Text>
                  </Link>
                </Box>
              </Grid.Col>

              {/* Services Links Section */}
              <Grid.Col span={{ base: 12, sm: 2, lg: 2 }}>
                <Text component="h4" className="lev-1" mb="sm">
                  Services
                </Text>
                <Box>
                  <Link href="/vendors" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Vendors
                    </Text>
                  </Link>
                  <Link href="/themes" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Browse Themes
                    </Text>
                  </Link>
                  <Link href="/timeline" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Timeline Templates
                    </Text>
                  </Link>
                  <Link href="/budget" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Budget Templates
                    </Text>
                  </Link>
                </Box>
              </Grid.Col>


              <Grid.Col span={{ base: 12, sm: 2, lg: 2 }}>
                <Text component="h4" className="lev-1" mb="sm">
                  Legal
                </Text>
                <Box>
                  <Link href="/privacy-policy" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                      Privacy Policy
                    </Text>
                  </Link>
                  <Link href="/terms-and-conditions" passHref>
                    <Text component="span" className="lev-2" display="block" mb="xs">
                     Terms & Conditions
                    </Text>
                  </Link>
                 
                
                </Box>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>
    </footer>
  );
};

export default WeddingFooter;